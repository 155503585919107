import { useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import type { ColumnFiltersState, RowSelectionState, Updater } from '@tanstack/react-table';
import { Plus } from 'lucide-react';
import { useCallback, useState } from 'react';

import type { Placement } from '@adframe/database-adframe/types';
import { platforms } from '@adframe/placement/schema';
import { useTranslation } from '@adframe/translation';
import { Button } from '@adframe/ui/components/button';
import { DataTable } from '@adframe/ui/components/data-table';
import usePlacement from '#app/hooks/use-placement.js';
import { useSheet } from '#app/providers/sheet.js';

import HasPermission from '#app/components/HasPermission.js';
import PlatformIcon from '@adframe/ui/components/platform-icon';
import { placementsQueryOptions } from '#app/queries/placement.ts';
import { placementColumns } from './columns/placement';
import PlacementForm, { type PlacementFormOutput } from './components/PlacementForm';

export const Route = createFileRoute('/_dashboard/placements/')({
	component: RouteComponent,
	loader: async ({ context }) => context.queryClient.ensureQueryData(placementsQueryOptions),
});

function RouteComponent() {
	const { updatePlacement, createPlacement, deletePlacement } = usePlacement();
	const { t } = useTranslation();
	const sheet = useSheet();
	const [placementSelected, setPlacementSelection] = useState<Placement | undefined>();

	const { data: placements } = useQuery(placementsQueryOptions);

	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

	const onRowSelected = useCallback(
		(value: Updater<RowSelectionState>) => {
			const id = Object.entries(
				typeof value === 'function'
					? value(placementSelected ? { [placementSelected.id]: true } : {})
					: value,
			)[0]?.[0];

			const placement = placements.find((row) => row.id === id);

			if (placement) {
				setPlacementSelection(placement);
				sheet.showSheet({
					onClose: () => setPlacementSelection(undefined),
					content: (
						<PlacementForm
							placement={placement}
							onSubmit={onSubmit}
							onRemove={async () => {
								await deletePlacement.mutateAsync({
									param: {
										placementId: placement.id,
									},
								});

								sheet.hideSheet();
							}}
							onCancel={() => {
								sheet.hideSheet();
							}}
						/>
					),
				});
			}
		},
		[placements],
	);

	const onSubmit = useCallback(
		async (form: PlacementFormOutput) => {
			console.log(form);

			if (form.id) {
				await updatePlacement.mutateAsync({
					json: {
						global: form.global,
						platform: form.platform,
						name: form.name,
						description: form.description,
						safezone: form.safezone,
						params: form.params,
					} as PlacementFormOutput,
					param: {
						placementId: form.id,
					},
				});
			} else {
				await createPlacement.mutateAsync({
					json: {
						global: form.global,
						platform: form.platform,
						name: form.name,
						description: form.description,
						safezone: form.safezone,
						params: form.params,
					} as PlacementFormOutput,
				});
			}
			setPlacementSelection(undefined);
			sheet.hideSheet();
		},
		[placementSelected],
	);

	return (
		<>
			<div className='flex flex-col gap-3'>
				<div className='flex justify-between gap-3'>
					<div className='-ml-2 -mt-2 flex w-2/3 flex-wrap items-baseline'>
						<h1 className='mt-2 ml-2 font-semibold text-base text-foreground leading-6'>
							{t('placement.menu')}
						</h1>
						<p className='mt-1 ml-2 truncate text-muted-foreground text-sm'>
							{t('placement.description')}
						</p>
					</div>
					<div>
						<HasPermission permission='placement-create'>
							<Button
								type='button'
								size={'sm'}
								onClick={() => {
									setPlacementSelection(undefined);
									sheet.showSheet({
										content: (
											<PlacementForm
												onSubmit={onSubmit}
												onCancel={() => {
													sheet.hideSheet();
												}}
											/>
										),
									});
								}}
							>
								<Plus className='mr-2 size-4' />
								{t('placement.create')}
							</Button>
						</HasPermission>
					</div>
				</div>
				<div>
					<ul className='hidden items-center gap-1 sm:flex'>
						<li>
							<Button
								data-state={!columnFilters.length ? 'active' : ''}
								size={'xs'}
								variant='ghost'
								onClick={() => setColumnFilters([])}
							>
								{t('placement.all')}
							</Button>
						</li>
						{platforms.map((platform) => (
							<li key={platform}>
								<Button
									data-state={
										columnFilters.some((filter) => filter.value === platform) ? 'active' : ''
									}
									size={'xs'}
									variant='ghost'
									onClick={() => setColumnFilters([{ id: 'platform', value: platform }])}
								>
									<PlatformIcon platform={platform} className='mr-1 size-3' />
									{t(`platform.${platform}`)}
								</Button>
							</li>
						))}
					</ul>
				</div>
				<DataTable
					columnFilters={columnFilters}
					columns={placementColumns()}
					data={placements.filter(({ deletedAt }) => !deletedAt)}
					enableRowSelection={true}
					enableMultiRowSelection={false}
					onRowSelectionChange={onRowSelected}
					rowSelection={placementSelected ? { [placementSelected.id]: true } : {}}
				/>
			</div>
		</>
	);
}
