import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';

import useOpenWindow from '#app/hooks/use-open-window.js';

export const Route = createFileRoute('/_dashboard/settings/integration/handle')({
	component: RouteComponent,
});

function RouteComponent() {
	const searchParams = new URLSearchParams(window.location.search);
	const { sendToParent } = useOpenWindow('integration');

	useEffect(() => {
		const message: Record<string, string> = {};
		for (const [key, value] of searchParams.entries()) {
			message[key] = value;
		}

		sendToParent(message);
	});

	setTimeout(() => {
		window.close();
	}, 500);
	return <></>;
}
