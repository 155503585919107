import { createFileRoute } from '@tanstack/react-router';
import { formatDistanceToNow } from 'date-fns';

import { useTranslation } from '@adframe/translation';
import { Button } from '@adframe/ui/components/button';
import { Check, ExternalLink, X } from 'lucide-react';
import { useMemo } from 'react';
import { toast } from 'sonner';
import useIntegration, { tokensQueryOptions } from '#app/hooks/use-integration.js';
import useMember from '#app/hooks/use-member.js';
import useOpenWindow from '#app/hooks/use-open-window.js';
import getIntegrationClient from '#app/services/internal-api/get-integration-client.js';

import {
	type AvailableProviders,
	availableProviders,
	tokenIsValid,
} from '@adframe/database-integration/helpers';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@adframe/ui/components/dropdown-menu';
import PlatformIcon from '@adframe/ui/components/platform-icon';
import { useSheet } from '#app/providers/sheet.tsx';
import TokenDetails from '../components/TokenDetails';

export const Route = createFileRoute('/_dashboard/settings/integration/')({
	component: RouteComponent,
	loader: async ({ context }) => context.queryClient.ensureQueryData(tokensQueryOptions),
});

function RouteComponent() {
	const { t } = useTranslation();
	const client = getIntegrationClient();
	const { exchange, tokens } = useIntegration();
	const sheetContext = useSheet();

	const { membersById } = useMember();

	const tokensFiltered = useMemo(
		() => tokens.filter((token) => !token.deletedAt || token.deletedAt < Date.now()),
		[tokens],
	);

	const { open } = useOpenWindow<
		{
			code: string;
			state: string;
			scope: string;
		},
		{ provider: AvailableProviders }
	>('integration', async (message, { provider }) => {
		exchange.mutate(
			{
				json: {
					code: message.code,
					state: message.state,
				},
				param: {
					provider,
				},
			},
			{
				onSuccess: () => {
					toast.success('Connected', {
						description: 'Please comeback in few minutes to see your connected accounts.',
					});
				},
				onError: () => {
					toast.error('Error');
				},
			},
		);
	});

	function onConnect(provider: AvailableProviders) {
		open(
			`${client.redirect[':provider'].$url({
				param: { provider },
			})}?r=${Date.now()}`,
			{ provider },
		);
	}

	return (
		<div>
			<div className='flex justify-between border-border border-b py-3'>
				<div>
					<h3 className='font-semibold text-foreground text-xl leading-6'>
						{t('settings.integration.head')}
					</h3>
				</div>
				<div>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button size={'sm'}>
								<ExternalLink className='mr-2 size-4' /> {t('settings.integration.connect')}
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent>
							{availableProviders.map(({ id, enable }) => (
								<DropdownMenuItem
									className='cursor-pointer'
									disabled={!enable}
									onClick={() => onConnect(id)}
									key={id}
								>
									<PlatformIcon platform={id} className='mr-2 inline-flex size-4' />
									{t(`settings.integration.provider.${id}`)}
								</DropdownMenuItem>
							))}
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			</div>
			<div className='mt-6'>
				{tokensFiltered.length ? (
					<ul className='divide flex flex-col gap-6 divide-border overflow-hidden'>
						{tokensFiltered.map((token) => (
							<li
								onClick={() => {
									sheetContext.showSheet({
										prevent: false,
										title: t('settings.integration.details.title'),
										content: <TokenDetails token={token} />,
									});
								}}
								key={token.id}
								className='relative flex cursor-pointer justify-between gap-x-6 bg-muted px-4 py-5 shadow-sm hover:bg-primary/10 sm:rounded-xl sm:px-6'
							>
								<div className='flex min-w-0 gap-x-4'>
									<PlatformIcon platform={token.provider} className='size-6 flex-none' />

									<div className='min-w-0 flex-auto'>
										<p className='font-semibold text-foreground text-sm leading-6'>
											<span className='-top-px absolute inset-x-0 bottom-0' />
											{t('settings.integration.advertiser_length', {
												value: token.advertisersToTokens.length,
											})}
										</p>
										<p className='mt-1 flex text-muted-foreground text-xs leading-5'>
											User ID: {token.providerUserId}
										</p>
										<p className='mt-1 flex text-muted-foreground text-xs leading-5'>
											Permissions: {token.scopes.join(', ')}
										</p>
										<p className='mt-1 flex text-muted-foreground text-xs leading-5'>
											{membersById[token.userId]?.user.firstName}{' '}
											{membersById[token.userId]?.user.lastName}
										</p>
									</div>
								</div>
								<div className='flex shrink-0 items-center gap-x-4'>
									<div className='hidden sm:flex sm:flex-col sm:items-end'>
										<p className='text-foreground text-sm leading-6'>
											{tokenIsValid(token) ? (
												<Check className='text-green-400' />
											) : (
												<X className='text-red-400' />
											)}
										</p>

										<p className='mt-1 text-gray-500 text-xs leading-5'>
											{t('settings.integration.connected', {
												value: formatDistanceToNow(token.createdAt, {
													addSuffix: true,
												}),
											})}
										</p>
									</div>
								</div>
							</li>
						))}
					</ul>
				) : (
					<p className='text-center text-foreground'>{t('settings.integration.empty')}</p>
				)}
			</div>
		</div>
	);
}
