import { createFileRoute } from '@tanstack/react-router';

import { useTranslation } from '@adframe/translation';
import { AvatarForm } from './components/AvatarForm';
import { NotificationForm } from './components/NotificationForm';
import { ProfileForm } from './components/ProfileForm';

export const Route = createFileRoute('/_dashboard/me')({
	component: RouteComponent,
});

function RouteComponent() {
	const { t } = useTranslation();

	return (
		<>
			<div className='p-6'>
				<div className='mt-2 md:flex md:items-center md:justify-between'>
					<div className='min-w-0 flex-1'>
						<h2 className='font-bold text-2xl text-foreground leading-7 sm:truncate sm:text-3xl sm:tracking-tight'>
							{t('user_settings.head')}
						</h2>
					</div>
				</div>
				<div className='divide-y divide-border'>
					<ProfileForm />
					<AvatarForm />
					<NotificationForm />
				</div>
			</div>
		</>
	);
}
